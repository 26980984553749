<template>
  <div class="news-list-wrap">
    <headNav active="3"></headNav>
    <div class="news-contents">
      <!-- 轮播图 -->
      <div class="news-carousel">
        <a-carousel dotsClass="home-dots" arrows>
          <div class="tac pointer" v-for="(item, key) in newsTopList" :key="key" @click="gotoDetail(item)">
            <img
              style="width: 100%"
              class="carousel-pic"
              :src="`${state.baseUrl}${item.thumb}`"
              alt=""
            />
          </div>
        </a-carousel>
      </div>

      <div class="news-list">
        <div class="w1200 item flex between pointer" v-for="(item,key) in newsList" :key="key" @click="gotoDetail(item)">
          <div class="pic">
            <img :src="`${state.baseUrl}${item.thumb}`" alt="">
          </div>
          <div class="text tal">
            <div class="time fs18 clighter">{{templateTime(item.inputtime)}}</div>
            <div class="title fs24 cmain pt16 pb40 ellipsis">
              {{item.title}}
            </div>
            <div class="tips fs16 clight ellipsis3">
              {{item.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footNav type="sub" />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import moment from  'moment'
export default {
  components: { headNav, footNav },
  data() {
    return {
      newsTopList: [],
      newsList: [],
    };
  },
  computed: {
    state() {
      return this.$store.state;
    },
  },
  created() {
    this.getNewsTop();
    this.getNewsList()
  },
  methods: {
    gotoDetail(item) {
      this.$router.push({ name: "news-detail", params: { id: item.id } });
    },
    getNewsTop() {
      this.$ajax
        .get("/cms/api/newslist", {
          dtype: "top",
        })
        .then((res) => {
          if (res.code == "200") {
            this.newsTopList = res.data.slice(0, 6);
            console.log(this.newsList);
          }
        });
    },
    templateTime(time) {
      if (!time) return
        return moment(time*1000).format('YYYY-MM-DD') 
    },
    // 获取新闻列表
    getNewsList() {
      this.$ajax.get("/cms/api/newslist", {}).then((res) => {
        if (res.code == "200") {
          this.newsList = res.data
          console.log(this.newsList);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.news-carousel {
  height: 768px;
  overflow: hidden;
  z-index: 0;
  .ant-carousel >>> .slick-slide {
    text-align: center;
    background: #364d79;
    overflow: hidden;
    height: 768px;
  }

  .ant-carousel .slick-list {
    height: 768px;
  }

  .home-dots.slick-dots {
    width: 1200px;
    text-align: left;
    margin: 0 auto;
    left: 50%;
    margin-left: -600px;
    bottom: 60px;
    height: 10px;
    display: flex;
    li {
      width: 10px;
      height: 10px;
      border: 2px solid #fff;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      border-radius: 10px;
      margin-right: 10px;
      button {
        width: 100%;
        height: 100%;
        opacity: 0;
        background: none;
      }
    }
    .slick-active {
      border: 2px solid #b49a71;
      width: 100px;
      height: 10px;
      box-sizing: border-box;
      button {
        width: 100%;
        background: none;
        opacity: 0;
      }
    }
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    width: 20px;
    height: 768px;
    background-color: #fff;
    top: 0;
    margin: 0;
    z-index: 100;
    &::before {
      content: "";
      display: block;
      width: 300px;
      height: 300px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      margin-top: -150px;
      border-radius: 300px;
    }
  }
  .ant-carousel .slick-prev {
    left: 0;

    &::before {
      left: -237px;
      background: #fff url("../assets/left.png") no-repeat 260px 50%;
    }
  }
  .ant-carousel .slick-next {
    right: 0;
    &::before {
      right: -237px;
      background: #fff url("../assets/right2.png") no-repeat 20px 50%;
    }
  }
}

.news-list {
  background: url("../assets/news/title-bg.png") no-repeat 50% 100px;
  padding: 260px 0 120px;
  .item {
    height: 265px;
    background: #fff;
    border-radius: 2px;
    padding: 32px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    margin-bottom: 24px;
    .pic {
      width: 360px;
      height: 200px;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    .text {
      width: 720px;
    }
  }
}
</style>
