<template>
  <div class="news-detail-wrap">
    <headNav active="3"></headNav>
    <div class="news-detail-contents w1200">
      <h2 class="h2">{{ detailInfo.title }}</h2>
      <div class="clighter fs18 tal pt36">
        <div>{{ templateTime(detailInfo.inputtime) }}</div>
        <div>苏州民族管弦乐团</div>
      </div>

      <div class="news-contents">
        <!-- <div class="tal mb40 fs18" v-html="detailInfo.description"></div> -->
        <div v-html="contents"></div>
      </div>
    </div>
    <footNav type="sub" />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import moment from "moment";
export default {
  components: { headNav, footNav },
  data() {
    return {
      id: "",
      type: "",
      detailInfo: {},
    };
  },
  computed: {
    state() {
      return this.$store.state;
    },
    contents() {
      let content = this.detailInfo.content || "";
      let newStr = content.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        function (match, capture) {
          if (!/http|https/.test(capture)) {
            return `<img src="https://www.suzhousco.com${capture}" alt="" />`;
          } else {
            return '<img src="' + capture + '" alt="" />';
          }
        }
      );
      return newStr;
    },
  },
  created() {
    this.id = this.$route.params.id || 0;
    this.type = this.$route.query.type || "";
    this.getNewsDetail();
  },
  methods: {
    // 获取演出列表
    getNewsDetail() {
      let requestUrl = "/cms/api/newsdetail";
      if (this.type == 'pinpaigongyi') {
        requestUrl = "cms/api/huimindetail";
      } else if (this.type == 'tuanxun') {
        requestUrl = "cms/api/tuanxundetail";
      }
      this.$ajax
        .get(requestUrl, {
          id: this.id,
        })
        .then((res) => {
          if (res.code == "200") {
            this.detailInfo = res;
          }
        });
    },
    templateTime(time) {
      if (!time) return;
      return moment(time * 1000).format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped lang="scss">
.news-detail-wrap {
  background-color: #eee;
}

.news-detail-contents {
  padding: 200px 0 120px;
}

.news-contents {
  background: #f5f5f5;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  margin-top: 60px;
  padding: 50px;
  border-radius: 10px;
}
</style>
